.carousel {
  display: flex;
  aspect-ratio: 192 / 60;
  width: 100%;
  box-shadow: inset 0px 0px 40px 20px rgba(0, 0, 0, 0.8);
  position: relative;
  z-index: 1000;
}

.carousel_pagination {
  position: absolute;
  top: 10px;
  right: 0px;
  transform: translate(-50%, 0);
}

.pagination_dot {
  height: 12px;
  width: 12px;
  background-color: #f5f5f5;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

.pagination_dot:hover {
  transform: scale(1.33);
}

.pagination_dot-active {
  background-color: steelblue;
}

@media screen and (max-width: 780px) {
  .carousel_pagination {
    width: 72px;
    top: 5px;
    left: 50%;
  }

  .pagination_dot {
    height: 10px;
    width: 10px;
    margin-left: 8px;
  }
}
